<template>
  <div class="newProblemList" v-if="isSelectTopic === false">
    <el-card>

    <form-create v-model="form" :rules="rules" ref="ruleForm" label-width="100px" size="small">
      <div class="form-item">
        <el-form-item label="题单标题" prop="title">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item :label="params[v].title" :prop="v" v-for="(v, i) in Object.keys(params).filter((v) => {
            return v !== 'know_ids'&&v !== 'time_str';
          })" :key="i">
          <el-select v-model="form[v]" :placeholder="'请选择' + params[v].title">
            <el-option :label="j.label" :value="j.value" v-for="(j, k) in params[v].list" :key="k"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间" prop="time_str"  >
              <el-date-picker v-model="form.time_str" type="year" placeholder="选择时间" value-format="yyyy"></el-date-picker>
            </el-form-item>
        <el-form-item label="知识点标签" prop="know_ids">
          <el-select v-model="form.know_ids" placeholder="请选择知识点标签" multiple collapse-tags>
            <el-option :label="v.title" :value="v.id" v-for="(v, i) in knowList" :key="i"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="时间限制" prop="">
          <div class="duration">
            <el-input placeholder="请输入" v-model="hour" type="number"  >
            <template slot="append">小时</template>
          </el-input>
          <el-input placeholder="请输入" v-model="minute" type="number" >
            <template slot="append">分钟</template>
          </el-input>
          </div>
          
        </el-form-item>
        <el-form-item label="价格" prop="down_price" v-if="userType==2">
          <el-input placeholder="请输入价格" v-model="form.down_price" type="number" :min="0">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
      </div>
      <el-form-item label="题单介绍" prop="description">
        <diy-form-item :item-type="0" v-model="form.description" v-bind="{prop:'description',label:'题单介绍' , type:'editor',editorConfig:{module:'default',initialFrameHeight:280,maximumWords:10000}}"></diy-form-item>
      </el-form-item>

    </form-create>
  </el-card>

    <div style="text-align: right;">
      <div class="button-primary" :style="{padding:'4px 20px'}" @click="edit">选择题目</div>
    </div>
  </div>
  <selectTopic :question-ids="questionIds" :form-topic="form" v-else></selectTopic>
</template>

<script>
import {know, create_params, detailList} from "@/api/problemList.js"
import selectTopic from "./selectTopic"
import {copyTD} from "@/api/topic";
var dayjs = require('dayjs')
export default {
  components:{selectTopic},
  data () {
    return {
       hour:'',
      minute:'',
      options: [],
      action: "",
      textarea: "",
      newProblemData: {
        inputTitle: "",
      },
      sourceList: [],
      value: "",
      knowList: [],
      know: [],
      form: {
        title: "",
        description: "",
        province_id: 0,
        diff_id: "",
        from_id: "",
        time_str: "",
        know_ids: [],
        duration: '',
        down_price: '',
      },
      rules: {
        title: [{ required: true, message: "请输入题单标题", trigger: "blur" }],
        description: [
          { required: true, message: "请输入题单介绍", trigger: "blur" },
        ],
        from_id: [{ required: true, message: "请选择来源", trigger: "change" }],
        diff_id: [{ required: true, message: "请选择难度", trigger: "change" }],
        province_id: [
          { required: true, message: "请选择地区", trigger: "change" },
        ],
        time_str: [
          { required: true, message: "请选择时间", trigger: "change" },
        ],
        know_ids: [
          { required: true, message: "请选择知识点标签", trigger: "change" },
        ],
        duration: [
          { required: true, message: "请输入时间限制", trigger: "blur" },
        ],
        down_price: [
          { required: true, message: "请输入价格", trigger: "blur" },
        ],
      },
      //选择项
      params: [],
      paramsProp: {},
	    isSelectTopic:false,
      problemDetail:{},
      questionIds:[],
    }
  },
  created () {
    this.action = this.$store.state.action
    if (this.$route.query.id) {
      this.getDetail(this.$route.query.id);
    }
    know({}).then((res) => {
      this.knowList = res.data.filter(v => v.title)
    })
    create_params({}).then((res) => {
      this.params = res.data
     
      let nowTime = dayjs().format('YYYY')
      let Time = dayjs().add(1, 'year').format('YYYY')
      this.params.time_str.list =[
        { label: nowTime, value:nowTime },
        { label: Time, value: Time },
      ]
    })
  },

  methods: {
    getDetail(id){
      this.problemLoading = true
      detailList({ id: id }).then(res => {
        this.problemLoading = false
        let data = res.data
        this.problemDetail = res.data
        data.down_price=data.down_price/100
        this.hour=parseInt(data.duration/3600)
        this.minute=  (data.duration-( parseInt(data.duration/3600)*3600)) / 60
        let arr = Object.keys(this.form)
        for (let v in data) {
          arr.map(j => {
            if (v === j && v != 'know_ids' && v != 'title') {
              this.form[v] = data[v]
            } else if (v == 'know_ids') {
              this.form[v] = data[v].split(",").map(k => k = Number(k))
            }
            this.form.title = data.title
          })
        }
        let qids = (data.questions_list||[]).map(d=>d._list.map(d=>d.id));
        this.questionIds = [];
        for (let i = 0; i < qids.length; i++) {
          this.questionIds = this.questionIds.concat(qids[i]);
        }
      }).finally((error) => {
        this.problemLoading = false
      })
    },
    edit () {
      if (Number(this.form.down_price) < 0) {
        this.$message({
          message: '价格不能位负数',
          type: 'warning'
        })
        return
      }
      
      let duration = 0
      if(this.hour){
        duration+=Number(this.hour)*60*60
      }
      if(this.minute){
        duration+=Number(this.minute)*60
      }
      this.form.duration= duration
      this.$refs["ruleForm"].validate((form,valid) => {
        if (valid) {
          if (Number(this.form.duration) <= 0) {
            return this.$message({
              message: '时间限制不能小于等于0',
              type: 'warning'
            })
          }
		      this.isSelectTopic = true;
        }
      })
    },
  },
};
</script>

<style lang="less" scoped>
.newProblemList {
  .duration{
    display: flex;
  }
  .editor {
    height: 360px;
    margin-bottom: 3.2rem;
    // background-color: #fff;
  }
  .form-item {
    display: flex;
    flex-wrap: wrap;
    > div {
      width: 50%;
    }
  }
  .item {
    ::v-deep {
      .el-form--inline .el-form-item__content {
        width: 100%;
      }
    }
  }

  font-size: 14px;
  color: #2f2f2f;
  > div {
    margin-top: 1.5rem;
  }
  .sourceSelect {
    .name {
      margin-left: 2rem;
      margin-right: 1rem;
    }
    .select {
      margin-left: 0.6rem;
    }
  }
  .source {
    // justify-content: flex-end;
    display: flex;
    align-items: center;
    .name {
      margin-right: 0.6rem;
    }
  }
  ::v-deep {
    .el-textarea__inner:focus {
      border-color: #979797;
    }
    .el-input__inner:focus {
      border-color: #979797;
    }
  }
}
</style>
