var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"selectTopic"},[_c('el-card',[_c('search-filter',{ref:"searchFilter",attrs:{"searchName":'搜索题目',"keywordOptions":{placeholder:'请输入题目名称或题目编号',prop:'title',autoName:'topic',autocomplete:true},"searchOptions":[
              { name:'el-form-item' ,options:{label:'难度' , prop:'diff_id', type:'radio-group' , data:{ name:'question', keys:'diff_id' } , defaultValue :'全部' }},
              { name:'el-form-item' ,options:{label:'来源' , prop:'from_id', type:'radio-group'  , data:{ name:'question', keys:'from_id' } , defaultValue :'全部'}},
              { name:'el-form-item' ,options:{label:'类型' , prop:'type', type:'radio' , data:{ name:'question', keys:'type2' } , defaultValue :'全部'}
              },
            ],"otherOptions":[
              { name:'el-form-item' ,options:{label:'年份' , prop:'time_str', type:'year'}
              },
              { name:'el-form-item' ,options:{label:'地区' , prop:'province_id', type:'select' , data:{ name:'question', keys:'province_id' , isAll:false }}},
            ],"total":_vm.operationTable.total},on:{"search":function($event){return _vm.currentChange(1)}},model:{value:(_vm.where),callback:function ($$v) {_vm.where=$$v},expression:"where"}})],1),_c('div',{staticClass:"operate"}),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"table-content"},[_c('ProblemListTable',{ref:"table",attrs:{"is-choice":true,"operation":_vm.operationTable,"rowSelection":_vm.rowSelection},on:{"selection":_vm.selection}},[_c('el-table-column',{attrs:{"slot":"topicType","label":"题目类型","align":"center"},slot:"topicType",scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"ProblemList-name"},[_vm._v(_vm._s(scope.row.type==1?'编程':'非编程'))])]}}])})],1),_c('div',{staticClass:"pagination"},[_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","total":_vm.operationTable.total,"page-size":_vm.page_size,"current-page":_vm.page,"hide-on-single-page":true},on:{"current-change":_vm.currentChange}})],1),_c('div',{staticClass:"affirm"},[_c('div',{staticClass:"button-primary",style:({ padding:'4px 20px'}),on:{"click":_vm.affirm}},[_vm._v("确认")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }