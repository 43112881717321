<template>
  <!-- 选择题目 -->
  <div class="selectTopic">
    <el-card>
      <search-filter
          ref="searchFilter"
          :searchName="'搜索题目'"
          :keywordOptions="{placeholder:'请输入题目名称或题目编号',prop:'title',autoName:'topic',autocomplete:true}"
          :searchOptions="[
                { name:'el-form-item' ,options:{label:'难度' , prop:'diff_id', type:'radio-group' , data:{ name:'question', keys:'diff_id' } , defaultValue :'全部' }},
                { name:'el-form-item' ,options:{label:'来源' , prop:'from_id', type:'radio-group'  , data:{ name:'question', keys:'from_id' } , defaultValue :'全部'}},
                { name:'el-form-item' ,options:{label:'类型' , prop:'type', type:'radio' , data:{ name:'question', keys:'type2' } , defaultValue :'全部'}
                },
              ]"
          :otherOptions="[
                { name:'el-form-item' ,options:{label:'年份' , prop:'time_str', type:'year'}
                },
                { name:'el-form-item' ,options:{label:'地区' , prop:'province_id', type:'select' , data:{ name:'question', keys:'province_id' , isAll:false }}},
              ]"
          v-model="where"
          :total="operationTable.total"
          @search="currentChange(1)"
      ></search-filter>
    </el-card>
    <div class="operate">
      <!-- <div class="bgGreen_btn" @click="join" v-if="userType == 2">导入</div> -->
      <!-- <div class="bgGreen_btn new_problemList" @click="newTopic" v-if="userType == 2">新建题目</div> -->
    </div>
    <div class="table-content" v-loading="loading">
      <ProblemListTable ref="table" :is-choice="true" :operation="operationTable" @selection="selection" :rowSelection="rowSelection">
        <el-table-column label="题目类型" align="center" slot="topicType">
                <template slot-scope="scope">
                  <!-- {{ scope.row.type_str }} -->

                  <span class="ProblemList-name">{{ scope.row.type==1?'编程':'非编程'}}</span>
                </template>
              </el-table-column>
      </ProblemListTable>
      <div class="pagination">
        <el-pagination background layout="prev, pager, next" :total="operationTable.total" @current-change="currentChange" :page-size="page_size" :current-page="page" :hide-on-single-page="true">
        </el-pagination>
      </div>
      <div class="affirm">
        <div class="button-primary" :style="{ padding:'4px 20px'}" @click="affirm">确认</div>
      </div>
    </div>
  </div>
</template>

<script>
import ProblemListTable from "@/components/ProblemListTable/ProblemListTable.vue"
import JoinPop from "./components/JoinPop.vue"
import { topicList, updateTopic } from "@/api/topic.js"
import { create } from "@/api/problemList.js"
import SearchFilter from "@/components/com/SearchFilter";
export default {
  components: { SearchFilter, ProblemListTable, JoinPop },
  props:{
	  formTopic:{
      type:Object,
      default:()=>{return {};}
    },
    questionIds:Array,
  },
  data () {
    return {
      where:{},
      loading: false,
      typeCourse: [],
      operationTable: {
        label: "题目名称",
        isSelection: true,
        time_str: true,
        tableData: [],
        total: 0,
      },
      //分页相关
      page: 1,
      page_size: 9,
      multipleSelection: [], // 选择的题目列表
      rowSelection: [],
    }
  },
  created () {
    this.page_size = Math.max(9,(this.questionIds||[]).length);
    this.getTopicList({ids:(this.questionIds||[]).toString()} , (data)=>{
      if((this.questionIds||[]).length){
        var rowSelection  = [];
        for (let i = 0; i < data.length; i++) {
          if(this.questionIds.indexOf(data[i].oid)>-1){
            rowSelection.push(data[i]);
          }
        }
        this.rowSelection = [...rowSelection];
      }
    })
  },
  methods: {
    // 选择的题目列表
    selection (list) {
      this.multipleSelection = list
    },
    // 点击确认
    affirm () {
      if (!this.multipleSelection.length) {
        this.$message({
          message: "请选择题目",
          type: "warning",
        })
        return
      }

      if (this.$store.state.topicType.name === '编辑题单') {
        updateTopic({
          id: this.$store.state.topicType.detail.id,
          ...this.formTopic,
          question_list_sub: this.multipleSelection.map(v => {
            return { question_id: v.id }
          }),
        }).then(res => {
          this.$message({
            message: res.msg,
            type: "success",
          })
          this.$router.push({ path: "/problemList/preview", query: { id: this.$store.state.topicType.detail.id } })
        })
      }else{
        create({ ...this.formTopic, questions: this.multipleSelection }).then((res) => {
          this.$message({
            message: res.msg,
            type: "success",
          })
          this.$router.push({ path: "/problemList/preview", query: { id: res.data.id } })
        })
      }
    },
    //获取题目列表
    getTopicList (param = {} , callback = null) {
      this.loading = true
      topicList({
        ...this.where,
        ...(param || {}),
        page: this.page,
        page_size: this.page_size,
      }).then(({ data }) => {
        this.operationTable.total = data.total
        this.operationTable.tableData = data.data
        callback && callback(data.data , data.total);
      }).finally(() => {
        this.loading = false
      })
    },
    currentChange (e) {
      if(this.page != e){
        this.page = e
        this.getTopicList()
      }
    },
  },
};
</script>

<style lang="less" scoped>
.selectTopic {
  .affirm {
    text-align: right;
  }
  .pagination {
    text-align: center;
    margin: 1.5rem 0 0 0;
  }
  .operate {
    margin: 2.2% 0;
    // margin-top: 40px;
    // margin-bottom: 0.5rem;
    display: flex;
    justify-content: flex-end;
    .new_problemList {
      margin-left: 1rem;
    }
  }
  .table-content {
    padding: 1rem;
    background-color: #fff;
  }
}
</style>
